import text from '../../data/text.json'
import {Card} from 'primereact/card'
import {Link} from 'react-router-dom'
import {routes} from '../../routes'
import {ProgressSpinner} from 'primereact/progressspinner'
import {useEffect, useState} from 'react'
import Users from '../../api/users'

const Dashboard = () => {
    const [isFetching, setIsFetching] = useState(false)
    const [data, setData] = useState({})
    const lang = 'az'

    const fetchData = async() => {
        setIsFetching(true)
        const [doctors, patients] = await Promise.all([Users.doctorReports(), Users.patientsReports()])
        setData({
            doctor_total: doctors?.total,
            patient_total: patients?.total
        })
        setIsFetching(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="statistics-page w-full">
            {isFetching ? (
                <div className="flex mt-5 justify-content-center align-items-center">
                    <ProgressSpinner/>
                </div>
            ) : (
                <div className="content w-full">
                    <p className="page-title">{text[lang].statistics_page_title}</p>
                    <div className="grid">
                        <div className="col-12 md:col-4">
                            <Link to={routes.doctors_statistics.path}>
                                <Card className="statistics-card">
                                    <div className="flex justify-content-between flex-wrap align-items-center">
                                        <span className="font-bold">{text[lang].doctor_statistics_page_title}</span>
                                        <span className="statistic-number">{data?.doctor_total}</span>
                                    </div>
                                </Card>
                            </Link>
                        </div>
                        <div className="col-12 md:col-4">
                            <Link to={routes.patient_statistics.path}>
                                <Card className="statistics-card">
                                    <div className="flex justify-content-between flex-wrap align-items-center">
                                        <span className="font-bold">{text[lang].patient_statistics_page_title}</span>
                                        <span className="statistic-number">{data?.patient_total}</span>
                                    </div>
                                </Card>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Dashboard
