import '../index.scss'
import text from '../../../data/text.json'
import {useForm} from 'react-hook-form'
import {InputText} from 'primereact/inputtext'
import {Card} from 'primereact/card'
import {Button} from 'primereact/button'
import Logo from '../../../assets/images/favicon.jpg'
import {useEffect, useState} from 'react'
import Auth from '../../../api/auth'
import {useNavigate} from 'react-router-dom'
import {routes} from '../../../routes'
import classNames from 'classnames'

const Login = () => {
    const lang = 'az'
    const {handleSubmit, setValue, formState: {errors}} = useForm({
        email: 'sahmar.quluzade7@gmail.com',
        password: 'password'
    })
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const [passwordIsShow, setPasswordIsShow] = useState(false)

    const login = async data => {
        setLoader(true)
        try {
            const res = await Auth.login(data)
            localStorage.setItem('token', res?.token)
            localStorage.setItem('user', JSON.stringify(res?.user))
            navigate(routes.profile.path)
        } catch (e) {
            console.log('Error')
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        setValue('email', 'sahmar.quluzade7@gmail.com')
        setValue('password', 'password')
    })
    return (
        <div className="auth-page">
            <div className="grid">
                <div className="col-12 md:col-4 md:col-offset-4">
                    <Card className="mt-5">
                        <div className="text-center">
                            <img src={Logo} width="200" height="100" alt="32 inci"/>
                        </div>
                        <div className="title">{text[lang].login}</div>
                        <form onSubmit={handleSubmit(login)} className="grid">
                            <div className="col-12">
                                <InputText
                                    className="w-full"
                                    value="office@threesteps.az"
                                    readOnly
                                    id="email"
                                    aria-describedby="email"
                                    placeholder={text[lang].email}
                                />
                                {errors.email && (
                                    <small className="form-element-is-invalid">
                                        {text[lang].field_required}
                                    </small>
                                )}
                            </div>
                            <div className="col-12">
                                <span className="p-input-icon-right w-full">
                                    <i onClick={() => setPasswordIsShow(!passwordIsShow)} className={classNames({
                                        'pi cursor-pointer': true,
                                        'pi-eye': !passwordIsShow,
                                        'pi-eye-slash': passwordIsShow
                                    })}/>
                                    <InputText
                                        className="w-full"
                                        value="demo1234"
                                        readOnly
                                        id="password"
                                        aria-describedby="password"
                                        placeholder={text[lang].password}
                                        type={passwordIsShow ? 'text' : 'password'}
                                    />
                                </span>
                                {errors.password && (
                                    <small className="form-element-is-invalid">
                                        {text[lang].field_required}
                                    </small>
                                )}
                            </div>
                            <div className="col-12">
                                <Button onClick={handleSubmit(login)}
                                    className="p-button-danger w-full text-center inline-block" disabled={loader}>
                                    {loader && <i className="pi pi-spin pi-spinner mr-1"/>}
                                    {text[lang].login}
                                </Button>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default Login
